import { addInterceptor } from "@/_error";
import * as Sentry from "@sentry/browser";
import OptOutConfig from "../cookies/thirdparty-cookies";

const sentryDSNTag = document.querySelector('meta[name="sentry-dsn"]')?.getAttribute("content");
const revision = document.querySelector('meta[name="tm-release"]')?.getAttribute("content") || "none";
const stage = document.querySelector('meta[name="tm-env"]')?.getAttribute("content") || "unknown";

const SENTRY_TRANSLATION_KEYS = [
    "triggerLabel",
    "formTitle",
    "submitButtonLabel",
    "cancelButtonLabel",
    "confirmButtonLabel",
    "addScreenshotButtonLabel",
    "removeScreenshotButtonLabel",
    "nameLabel",
    "namePlaceholder",
    "emailLabel",
    "emailPlaceholder",
    "isRequiredLabel",
    "messageLabel",
    "messagePlaceholder",
    "successMessageText",
]

function initSentry() {
    if (!(!!sentryDSNTag && OptOutConfig.is_activated("error"))) return;
    let killed = false;

    Sentry.init({
        dsn: sentryDSNTag,
        release: revision,
        environment: stage,
        autoSessionTracking: false,
        defaultIntegrations: false,
        tunnel: "/sentry",
        integrations(integrations) {
            const SKIP = [
                "Breadcrumbs",
                "CaptureConsole",
                "GlobalHandlers",
                "BrowserApiErrors"
            ];
            const defaultIntegrations = integrations.filter((integration) => {
                return !SKIP.includes(integration.name);
                
            });
            console.log(defaultIntegrations);

            return [...defaultIntegrations];
        }
    });

    // Send errors to Sentry.
    addInterceptor(900, (value: any, extras: Record<string, any>) => {
        if (!killed && OptOutConfig.is_activated("error")) {
            Sentry.withScope((scope) => {
                for (let [k, v] of Object.entries(extras)) {
                    scope.setExtra(k, v);
                }

                if (value instanceof Error) {
                    Sentry.captureException(value);
                } else {
                    Sentry.captureMessage("" + value);
                }
            });
        }
        return false;
    });

    // Exit sentry when disabling.
    OptOutConfig.onChange("errors", (after) => {
        if (!after) {
            killed = true;
            Sentry.close(2000).catch(window.reportError);
        }
    });

    // Send User Feedback
    interface FeedbackIntegration {
        integration: any;
        widget: any;
    }
    let currentFeedback: FeedbackIntegration|null = null;
    OptOutConfig.onChange("errors", (after) => {
        if (!(!after && currentFeedback)) return;
        currentFeedback.widget.removeFromDom();
    })

    addInterceptor(900, (_: any) => {
        if (killed) return false;

        const tryCapture = (e: any) => {
            try {
                Sentry.captureException(e)
            } catch (e2) {
                console.error(e2);
            }
        }
        (async () => {
            const { tr } = await import("@/utils/translations");
            if (currentFeedback) return;

            let currentKeys: Record<string, string> = {};
            try {
                currentKeys = Object.fromEntries(SENTRY_TRANSLATION_KEYS.map(k => [k, tr(`sentry.feedback.${k}`)]));
            } catch (e) {
                tryCapture(e);
            }

            const feedback = Sentry.feedbackIntegration({
                ...currentKeys,
                onSubmitSuccess() {
                    currentFeedback?.widget.removeFromDom();
                    currentFeedback = null;
                },
                onSubmitError() {
                    currentFeedback?.widget.removeFromDom();
                    currentFeedback = null;
                }
            });

            const widget = feedback.createWidget({});
            currentFeedback = {
                integration: feedback,
                widget: widget
            }
        })().catch(tryCapture);
        return false;
    });
}

try { 
    // Reload page when enabling.
    OptOutConfig.onChange("errors", (after) => {
        if (after) {
            setTimeout(() => {window.location.reload()}, 100);
        }
    });
    initSentry();
} catch (e) {
    window.reportError(e);
}
