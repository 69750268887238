import Cookies from "js-cookie";
import { exposeObject } from "@/utils/pages";

function _can_use_localStorage() {
    if (!window.localStorage) return false;
    window.localStorage.setItem('_tmng__can_use_localStorage', "true");
    try {
        return !!window.localStorage.getItem('_tmng__can_use_localStorage');
    } finally {
        window.localStorage.removeItem('_tmng__can_use_localStorage');
    }
};

const THIRDPARTY_CONFIGURATION_VERSION_COOKIE_NAME = "_tmng__3rdparty_version";
const THIRDPARTY_CONFIGURED_COOKIE_NAME = "_tmng__3rdparty_is_configured";
const THIRDPARTY_SETTING_COOKIE_NAME = "_tmng__3rdparty_activated";
const COOKIE_WHITELIST: (string|RegExp)[] = [
    '__cfduid',
    '_session_id',
    '_passenger_route',
    "__queue_token",
    THIRDPARTY_CONFIGURATION_VERSION_COOKIE_NAME,
    THIRDPARTY_CONFIGURED_COOKIE_NAME,
    THIRDPARTY_SETTING_COOKIE_NAME,
];

function _is_whitelisted(cookie_name: string) {
    for (var i in COOKIE_WHITELIST) {
        var _rule = COOKIE_WHITELIST[i];
        if (_rule instanceof RegExp) {
            if (_rule.test(cookie_name)) return true;
        } else {
            if (_rule === cookie_name) return true;
        }
    }
    return false;
};

function _strip_cookies() {
    for (var _cname in Cookies.get()) {
        if (!_is_whitelisted(_cname))
            Cookies.remove(_cname);
    }
};


interface StorageEngine {
    get(key: string): any;
    set(key: string, value: any): void;
}


class LocalStorageEngine implements StorageEngine {
    get(key: string): any {
        const result = window.localStorage.getItem(key);
        if (result === null) return null;
        return JSON.parse(result);
    }

    set(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}

class CookieStorageEngine implements StorageEngine {
    get(key: string): any {
        return JSON.parse(Cookies.get(key) || 'null');
    }
    set(key: string, value: any) {
        if (value === null)
            Cookies.remove(key);
        else
            Cookies.set(key, JSON.stringify(value));
    }
}


const storage: StorageEngine = _can_use_localStorage() ? new LocalStorageEngine() : new CookieStorageEngine();

class UserConfiguration {
    dntEnabled: boolean;
    changeHandlers: Record<string, ((state: boolean, before: boolean)=>void)[]>;
    
    constructor() {
        var dntSetting = ((<any>window.navigator).msDoNotTrack || window.navigator.doNotTrack);
        this.dntEnabled = dntSetting === '1' || dntSetting === 'yes';
        this.changeHandlers = {};
        if (this.dntEnabled)
            console.debug("Detected Do-Not-Track setting. Setting default FALSE");
    }

    get state(): Record<string, boolean> {
        if (!this.is_configured()) return {statistics: false, error: true};
        return JSON.parse(storage.get(THIRDPARTY_SETTING_COOKIE_NAME));
    }

    is_configured() {
        return (storage.get(THIRDPARTY_CONFIGURATION_VERSION_COOKIE_NAME) === "v3") && (!!storage.get(THIRDPARTY_CONFIGURED_COOKIE_NAME));
    }

    is_activated(type: string) {
        return this.state[type] || false;
    }

    onChange(type: string, cb: (after: boolean, before: boolean) => void) {
        (this.changeHandlers[type]||=[]).push(cb);
    };

    set_activated(type: string, state: boolean) {
        const before = this.state[type];

        storage.set(THIRDPARTY_SETTING_COOKIE_NAME, JSON.stringify({...this.state, [type]: state}));
        storage.set(THIRDPARTY_CONFIGURED_COOKIE_NAME, true);
        storage.set(THIRDPARTY_CONFIGURATION_VERSION_COOKIE_NAME, "v3");

        if (before !== state) {
            (this.changeHandlers[type]||[]).forEach((c) => c(state, before));
        }
    }
}

const UserConfig = new UserConfiguration();
UserConfig.onChange("statistics", (after) => {if (!after) { setTimeout(() => {window.location.reload();}, 100) }});
UserConfig.onChange("statistics", (after) => {if (!after) { _strip_cookies() }});

exposeObject("OptOutConfig", UserConfig);

if (!UserConfig.is_activated("statistics")) {
    _strip_cookies();
} else {
    try {
        (() => {
            var _paq = (window as any)._paq = (window as any)._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u="//stats.ticketmachine.de/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '1']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src=u+'matomo.js'; s.parentNode?.insertBefore(g,s);
            })();
        })();
    } catch(e) {
        window.reportError(e);
    }
}

export default UserConfig;


