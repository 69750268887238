/// WARNING: This function is part of the early-boot process of the application.
///          You are not allowed to import anything that causes a `tm:*` import.

import ModuleLoader from "@/platform/modules";

export type EntryPoint = (...anyParams: any)=>Promise<void|((replace: boolean) => (Promise<void|boolean>|void|boolean))>;
export interface EsModule<T> {
    default: T,
    hot?: {
        accept: (cb: () => void) => void,
        decline: (deps?: string[]) => void,
        dispose: (cb: () => void) => void
    }
}


const _onLoadModules: (()=>Promise<any>)[] = [];
let loaded: boolean = false;
ModuleLoader.module("@ticketmachine/backendv1", [], async ()=>{
    await Promise.all(_onLoadModules.map(l => l()));
    loaded = true;
});

window.setTimeout(() => {
    ModuleLoader.require("@ticketmachine/backendv1");
}, 100);



/**
 * Things that should always be loaded, but require all scripts to be fully loaded
 * should be run inside onLoad()
 *
 * On load makes sure all legacy initialization code has run to completion.
 */
export function onLoad(module: ()=>Promise<any>): void {
    if (loaded) {
        module().then(()=>{}, window.reportError);
    } else {
        _onLoadModules.push(module);
    }
}


export function defineModule<V, T extends ()=>V>(name: string, loader: T) {
    ModuleLoader.module(name, [], async () => {
        return await loader();
    });
};


/**
 * Exports a raw module into the module system.
 */
export function exportModuleRaw<V, T extends ()=>Promise<V>>(name: string, loader: T) {
    ModuleLoader.module(name, [], async () => {
        return await loader();
    }, true);
}


/**
 * Export module allows you to use node_modules in slightly older code.
 *
 * As an example
 * 
 *     exportModule("vue", () => import("vue"));
 *
 * Allows you to import it in a cjs.js file inside assets.
 *
 *     import Vue from "vue";
 */
export function exportModule<V, T extends ()=>Promise<V>>(name: string, loader: T) {
    exportModuleRaw(name, async () => {
        const module = await loader();
        if ((<any>module).__esModule) {
            return (<any>module).default;
        } else {
            return module;
        }
    });
}


/**
 * Exposes the default export of a module to the window object.
 */
export function exposeModule<V, T extends ()=>{default: V}>(name: string, loader: T) {
    (<any>window)[name] = loader().default;
}

/**
 * Exposes a value to the window namespace.
 */
export function exposeFunction(name: string, func: (...params: any) => any) {
    (<any>window)[name] = func;
}

/**
 * Exposes the default function of a module.
 * The default function must be asynchronous
 */
export function exposeLazyFunction(name: string, loader: () => Promise<{default: (...params: any) => Promise<any>}>) {
    exposeFunction(name, async (...args) => {
        const module = await loader();
        return await module.default(...args);
    });
}

/**
 * Exposes an object to the window namespace,
 * but avoids redefine the object.
 */
export function exposeObject(name: string, obj: any, redefine=false) {
    if (redefine || !((<any>window)[name]))
        (<any>window)[name] = obj;
}

/**
 * EntryPoint Modules are another way to implement entry-points:
 *
 * Let's start with an example:
 *
 *     entrypointModule("moduleName", () => import("pathToFile.ts"));
 *
 * This allows you to only load the file when the module is actually being requirested.
 * Thus the dependencies of that file are only evaluated once the file is actually to be loaded.
 * The module in question has a default export with the function that should be called.
 *
 * This solves the problem of the imports made inside that file are evaluated immediately, regardless
 * of wether the entrypoint is actually being called or not. Since these imports can actually
 * resolve urls, download stuff and check permissions, this code prevents that from happening
 * every page load and instead ensures that this only happens once.
 *
 * This makes sure the module is only loaded once the entry-point is actually requested.
 */
export function entrypointModule<T extends EntryPoint>(name: string, func: ()=>Promise<EsModule<T>>) {
    ModuleLoader.module(name, [], async () => {
        let onReload: (replace: boolean) => void|Promise<void|boolean>|boolean = async () => {};

        const mod = await func();
        const loader = mod.default;

        return async (...args: any[]) => {
            await onReload(false);
            const newReloader = await loader(...args);
            if (typeof newReloader === "function") {
                onReload = async (reloaded) => {
                    onReload = async () => {};
                    const result = await newReloader(reloaded);
                    return result;
                };
            } 
        };
    }, true);
    return func;
}

/**
 * Creates a new reloadable entrypoint.
 *
 * This allows the entrypoint to automatically reload itself on enabling.
 */
export function reloadableEntrypoint<T extends EntryPoint>(module: NodeModule, name: string, ep: T): EntryPoint {
    let onReload: (replace: boolean) => Promise<boolean|void>|void|boolean = async()=>{};
    let lastArgs: any[]|null = null;
    module.hot?.accept();
    module.hot?.dispose(() => {
        const lastReload = onReload;
        onReload = async () => {};
        const result = lastReload(true)
        if (typeof result === "object" && typeof result["then"] === "function")
            result.then(() => {}, window.reportError);
    });
    return (async (...args: any[]) => {
        lastArgs = [...args];
        const result = await ep(...args);

        onReload = async (replace) => {
            const newLastArgs = lastArgs;
            onReload = async() => {};
            lastArgs = null;

            let shallReenable = false;
            if (typeof result === "function") {
                shallReenable = await result(replace) || false;
            }
            ModuleLoader.unload(name);

            if (shallReenable && replace && newLastArgs !== null) {
                await (await ModuleLoader.require(name))(...newLastArgs);
            }
        };

        return async() => { return await onReload(false); };
    });
}

interface SvelteEntryPointModule<T extends typeof import("*.svelte")> {
    mount?: (self: T["default"], ...args: any[]) => any;
    default: T["default"];
}

/**
 * This function allows svelte components with an exported mount function to act as
 * components.
 *
 * A svelte component include this in its code to make it work:
 *
 *     <script context="module" lang="ts">
 *         export function mount(self, ARGS) {
 *         
 *         }
 *     </script>
 *
 * If the script does not contain any mount function, a default entry-point will be created that
 * takes one argument, a query-selector, which is used to find the element to mount the svelte target
 * on.
 */
export function entrypointSvelte<C extends typeof import("*.svelte")>(name: string, func: ()=>Promise<C>) {
    entrypointModule(name, async () => {
        const m = await func();
        if ((<any>m).mount === undefined) {
            return {
                default: async (...anyArgs: any[]) => {
                    let target;
                    let count = 0;
                    while ((target = document.querySelector(anyArgs[0])) === null) {
                        if (count > 10) return;
                        count++;
                        await new Promise(rs => setTimeout(rs, 100));
                    }

                    const opts = {target, props: {}};
                    if (anyArgs.length > 2)
                        opts.props = anyArgs[1];

                    return new m.default(opts);
                }
            };
        } else {
            return {
                default: (...anyArgs: any[]) => (<SvelteEntryPointModule<C>><any>m).mount!(m.default, ...anyArgs)
            };
        }

    });
}

