/// WARNING: This function is part of the early-boot process of the application.
///          You are not allowed to import anything that causes a `tm:*` import.
import { exportModule, exposeObject } from "@/utils/pages";

function format(string, params) {
    if (typeof string !== "string") string = JSON.stringify(string).toString();
    return string.replace(/{(\d+)}/g, (match, number) => {
        return typeof params[number] != 'undefined'
            ? params[number]
            : match
            ;
    })
}


var translation_cache = {};

var translations = 
    !!window["I18n"] ? (
        (!!window.DynamicTranslations)
        ? window.DynamicTranslations[window.I18n.config.tag].javascript
        : window.I18n
    ) : {};

var _wrap_funcinst = function(func_maker) {
    let func = null;
    return function(i) {
        if (func === null) func = func_maker();
        var n = (i<0)?Math.ceil(i):Math.floor(i);
        var f = Number.parseInt((i%1).toString().substring(2));
        return func(n, f);
    }
}

var parse_rules = (function() {
    var _parse_rules = function rules(rules) {
        var result = {}
        for (var rname in rules) {
            result[rname] = _wrap_funcinst(() => new Function('n, f', 'return ' + rules[rname] + ';'));
        }
        return result;
    };

    var parse_rules = function rules(rules) {
        return {
            ordinal: _parse_rules(rules.ordinal),
            cardinal: _parse_rules(rules.cardinal),
            language_code: rules.language_code,
            currency: rules.currency,
            tag: rules.tag
        }
    };
    return parse_rules;
})();

var language_rules = parse_rules(translations.config || {
    cardinal: {one: 'n == 1'},
    ordinal: {},
    language_code: 'de-DE',
    currency: 'EUR',
    tag: "de"
});
var find_rule_for_n = function find_rule_for(n, type) {
    if (type === undefined) type = "cardinal";
    for (var plural_type in language_rules[type]) {
        if (language_rules[type][plural_type](n)) {
            return plural_type;
        }
    }
    return 'other';
};

var _resolve_name = function(name) {
    if (translation_cache[name] !== undefined) return translation_cache[name];

    var split_name = name.split(".");
    var current = translations;
    for (var index in split_name) {
        var current_name = split_name[index];
        var candidate = current[current_name];
        if (!candidate && !isNaN(parseInt(current_name))) candidate = current[parseInt(current_name)];
        if (!candidate) {
            current = null;
            break;
        }
        current = candidate;
    }

    translation_cache[name] = current;
    return current;
};

var _in_cache_and_exists = function _in_cache(name) {
    return !!translation_cache[name];
};

var _fill_cache_at = function _fill_cache_at(name) {
    if (typeof translation_cache[name] === "string") return translation_cache[name];
    var tl = _resolve_name(name);
    if (typeof tl === "object" && tl !== null) {
        var keys = Object.keys(tl);
        for (var key in keys) {
            _fill_cache_at(name + "." + keys[key]);
        }
    }

    return translation_cache[name];
};

var _product = function(arrays) {
    if (arrays.length === 0) return [];

    arrays = arrays.slice();
    var result = [];
    var init = arrays.shift();
    if (arrays.length === 0) {
        for (var i = 0; i<init.length; i++) {
            result.push([init[i]]);
        }
    } else {
        var sub_product = _product(arrays);
        for (var i = 0; i<init.length; i++) {
            for (var j = 0; j<sub_product.length; j++) {
                var product = sub_product[j].slice();
                product.unshift(init[i]);
                result.push(product);
            }
        }
    }

    return result;
};

/**
 * Find the translation key for the given language.
 *
 * @param name             The name of the key.
 * @param def              The default-value if the key couldn't be found.
 * @returns string|null    The translated string
 */
export function _(name, def) {
    return _resolve_name(name) || def;
};

/**
 * Trsanslate the string. Add formatting (e.g. {0}, {1}, ...) and object
 * localization.
 *
 * @param name            The translation key.
 * @param def             The default text (or set to undefined)
 * @param params          An array of parameters.
 * @returns string|null   The translated string
 */
export function t(name, def, params) {
    if (params === undefined) params = [];
    var translated = _(name, def);
    if (translated === null) return null;
    if (!(params instanceof Array))
        params = [params];
    for (var pKey in params)
        params[pKey] = l(params[pKey]);
    if (!translated) translated = "Translation missing: " + name;
    return format(translated, params);
};

/**
 * Translate the string. Add formatting and support pluralization.
 *
 * The integer will be converted into the correct type and then joined by
 * hypens (-) and be added as subkey to the translation key.
 *
 * E.g. name='abc.def'; ns=[1,3,7] in en => 'abc.def.one-few-other'
 *
 * @param name          The translation base-key
 * @param def           The default string.
 * @param ns            The numbers.
 * @param params        The parameters.
 * @returns string|null The translated string.
 */
export function tn(name, def, ns, params) {
    if (typeof _fill_cache_at(name) !== "object") return t(name, def, params);

    if (!(ns instanceof Array)) ns = [ns];
    ns = JSON.parse(JSON.stringify(ns));
    if (ns.length === 0) return t(name, def, params);

    for (var i in ns) {
        var n = ns[i];
        if (!(n instanceof Array)) {
            n = [n];
        }
        if (typeof n[0] === "string") {
            ns[i] = [n[0], "any"];
            continue;
        }
        if (typeof n[0] !== "number") {
            ns[i] = [n[0]?.toString(), "any"];
            continue;
        }
        ns[i] = ["" + n[0], find_rule_for_n(n[0], n[1])];
    }

    var possible_rules = _product(ns);
    for (var pr in possible_rules) {
        var tested_name = name + "." + possible_rules[pr].join('-');
        if (_in_cache_and_exists(tested_name)) {
            name = tested_name;
            break;
        }
    }
    return t(name, def, params);
};

export function pushCache(name, text) {
    translation_cache[name] = text;
}

/**
 * Translate the string. Automatically chooses the best translation function.
 *
 * Can have between 1 and 4 arguments.
 * These signatures exist:
 * - tr(name)                  => _(name, name)
 * - tr(name, def)             => _(name, def)
 * - tr(name, params)          => t(name, name, params) or tr(name, name, params, params)
 * - tr(name, def, params)     => t(name, def, params)  or tr(name, name, params, params)
 * - tr(name, ns, params)      => tn(name, name, ns, params)
 * - tr(name, def, ns, params) => tn(name, def, ns, params)
 *
 * It will choose the correct function by the type of passed parameters.
 *
 * @param name    [type: string]           The translation key.
 * @param def     [type: string; optional] The text to use if the translation is missing.
 * @param ns      [type: array; optional]  The key-parameters. See `tn` documentation.
 * @param params  [type: array; optional]  The values for the placeholders.
 */
export function tr(name, def, ns, params) {
    // tr(name)
    if (def === undefined && ns === undefined && params===undefined)
        return _(name, name);

    // tr(name, def)
    else if (typeof def === "string" && ns === undefined && params === undefined)
        return _(name, def);

    // tr(name, params)
    else if (def instanceof Array && ns === undefined && params === undefined) {
        if (typeof _fill_cache_at(name) === "string")
            return t(name, name, def);
        else
            return tn(name, name, def, def);
    }
    // tr(name, def, params)
    else if (typeof def === "string" && params === undefined) {
        if (typeof _fill_cache_at(name) === "string")
            return t(name, def, ns);
        else
            return tn(name, def, ns, ns);
    }

    // tr(name, ns, params)
    else if (def instanceof Array && params === undefined)
        return tn(name, name, def, ns);

    // tr(name, def, ns, params)
    else
        return tn(name, def, ns, params);
};

const DIVISIONS = [
  { amount: 60, name: 'seconds', clock: (i) => i.toString().padStart(2, "0") },
  { amount: 60, name: 'minutes', clock: (i) => i.toString().padStart(2, "0") },
  { amount: 24, name: 'hours', clock: (i) => i.toString() },
  { amount: 7, name: 'days', clock: null },
  { amount: 4.34524, name: 'weeks', clock: null },
  { amount: 12, name: 'months', clock: (i) => i.toString() },
  { amount: Number.POSITIVE_INFINITY, name: 'years', clock: (i) => new Intl.NumberFormat(language_rules.language_code).format(i) }
]

function formatTimeAgo(formatter, date) {
  let duration = (date - new Date()) / 1000

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

/**
 * Localize the object.
 *
 * If the browser can't convert it, we will just make a string out of it.
 *
 * @param obj       The object to localize
 * @param options   Some browser features accept options. Pass them here.
 * @returns string
 */
export function l(obj, config) {
    if (obj === null || obj === undefined) {
        return "";
    }

    let language = language_rules.language_code;
    if (!!config && config.language) {
        language = config.language;
        delete config.language;
    }

    if (!!config && config.type === "date" && typeof obj.toLocaleDateString === "function") {
        delete config.type;
        return obj.toLocaleDateString(language, config);
    }

    if (!!config && config.type === "time" && typeof obj.toLocaleTimeString === "function") {
        delete config.type;
        return obj.toLocaleTimeString(language, config);
    }

    if (!!config && config.type === "date:relative" && typeof window.Intl.RelativeTimeFormat === "function") {
        delete config.type;
        return formatTimeAgo(new Intl.RelativeTimeFormat(language, config), obj);
    }
    if (!!config && config.type === "number") {
        delete config.type;
        return new Intl.NumberFormat(language, config).format(obj);
    }
    
    if (!!config && (!!config.currency || config.style === "currency")) {
        if (config.currency === true || config.currency === undefined) config.currency = language_rules.currency;
        return (new Intl.NumberFormat(language, {style: "currency", currency: config.currency})).format(obj);
    }

    if (typeof obj.toLocaleString === "function") {
        if (!!config && config.type === "datetime")
            delete config.type;
        return obj.toLocaleString(language, config);
    }

    return obj.toString();
};

/**
 * Returns a number from the localized number format.
 * @param string The text to parse
 */
export function parseNumber(string) {
    var negative = string[0] === "-" ? -1 : 1;
    if (negative === -1) {
        string = string.substring(1, string.length);
    }

    var number = translations.number.format;
    var data = string.split(number.separator);
    if (data.length === 1) {
        return window.parseInt(data[0].replace(/[^0-9]/g, '')) * negative;
    } else if (data.length === 2) {
        var value = data[0].replace(/[^0-9]/g, "");
        var fraction = data[1].replace(/[^0-9]/g, "");

        return window.parseFloat(value + "." + fraction) * negative;
    } else {
        return NaN;
    }
}

export const languages = ["de", "en"];
export const code = language_rules.language_code;
export const language = language_rules.tag;
export const datatables = translations.datatables;

const exported = {_, t, tn, tr, l, code, parseNumber, languages, datatables, language};
export default exported;
exposeObject("Translations", exported);
exportModule("widgets/helpers/translations", async () => exported);
