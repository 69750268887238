// Boot-Process for the javascript.
// The order of each line might have drastic results.
//
// Currently the boot order is as follows:
// - Platform code (The things that make TicketMachine run)
// - Legacy code
// - Modules and Entrypoints
// - Tasks
// - (Loaded asynchronous: Autorun code)

// Actually a no-op. Makes .css appear.
import "./backend/stylesheet";

// Show a spinner for the start.
import boot from "./_boot";

// Make jquery available early.
(window as any).$ = require("@vendor/stage0/0_jquery.js");
(window as any).jQuery = require("@vendor/stage0/0_jquery.js");

(async (steps: (() => Promise<void>)[]) => {
    try {
        boot.start();
        for (let step of steps) {
            try {
                await step();
            } catch (e) {
                window.reportError(e);
            }
        }

        console.log("ticketmachine: Bootup complete...");
    } finally {
        boot.finish();
    }
})([
    // Load the platform code
    async () => {
        require("./_error");
        require("./polyfills");
        await Promise.all([
            import(/* webpackMode: "eager" */ "./platform"),
            import(/* webpackMode: "eager" */ "./utils/translations"),
            import("./_errors/backend")
        ]);
        console.debug("ticketmachine: Platform code loaded.");
    },

    // Execute legacy code.
    async () => {
        await import(
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            "./legacy/index"
        );
        console.debug("ticketmachine: Legacy code executed.") 
        boot.finish();
    },

    // Load modules as background tasks.
    async () => {
        await Promise.all([
            import(/* webpackMode: "lazy-once" */ "./modules"),
            import(/* webpackMode: "lazy-once" */ "./tasks")
        ]); 
        console.debug("ticketmachine: Modules loaded.");
    },

    // Auto-run stuff
    async () => {
        await import(
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            "./autorun"
        );
        console.debug("ticketmachine: Autorunning code loaded");
    }
]).catch(window.reportError);
