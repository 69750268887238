/**
 * window.requestIdleCallback()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
 */
if (!window["requestIdleCallback"]) {
  window["requestIdleCallback"] = function (callback: IdleRequestCallback, options?: IdleRequestOptions) {
    var opts = options || {};
    var relaxation = 1;
    var timeout = opts.timeout || relaxation;
    var start = performance.now();
    return <any>setTimeout(function () {
      callback({
        get didTimeout() {
          return opts.timeout ? false : (performance.now() - start) - relaxation > timeout;
        },
        timeRemaining: function () {
          return Math.max(0, relaxation + (performance.now() - start));
        },
      });
    }, relaxation);
  };
}

