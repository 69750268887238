// This module is loaded early.
// Do not import in this module anything that uses tm:
const INTERCEPTORS: [number, (v: any, extra: Record<string, any>) => boolean][] = [];

const MESSAGES = {
    base: {
        object: "A promise rejected with this object:",
        exception: "An error occured:"
    },

    cause: {
        object: "The error was caused by this object:",
        exception: "The above exception was caused by this error:"
    }
}

export function addInterceptor(priority: number, cb: (v: any, extra: Record<string, any>) => boolean) {
    INTERCEPTORS.push([priority, cb]);
}


function dumpError(type: keyof (typeof MESSAGES), e: any, extra: Record<string, any>) {
    console.group(MESSAGES[type].exception)
    if (e instanceof Error) {
        console.error(e);
        if (!!e.cause) 
            dumpError("cause", e.cause, {});

        if (Object.keys(extra).length > 0) {
            console.groupCollapsed("The following metadata has been added:");
            for (let [k, v] of Object.entries(extra)) {
                console.debug(`${k}:`, v);
            }
            console.groupEnd();
        }

    } else {
        console.error(MESSAGES[type].object, e)
    }
    console.groupEnd();
}

export function reportError(e: any, extra: Record<string, any>) {
    dumpError("base", e, extra) 
}

window.reportError = (e: any, extra: Record<string, any> = {}) => {
    const interceptors = [...INTERCEPTORS];
    interceptors.sort(([a, _], [b, __]) => (a-b)/Math.abs(a-b));

    for (let [_, interceptor] of interceptors) {
        if (interceptor(e, extra)) return;
    }
    reportError(e, extra);
}

// Handle null or undefined as errors first.
addInterceptor(0, (value: any) => {
    if (value === null) {
        console.error("`null` was sent as an error.");
        return true;
    } else if (value === undefined) {
        console.error("`undefined` was sent as an error.");
        return true;
    }

    return false;
});

addInterceptor(100, (_, record) => {
    record["user-agent"] = navigator.userAgent;
    record["url"] = window.location.href.toString();
    return false;
});

require("./_errors/sentry");
