// This is almost literally the first code run by the application.
// Do not import anything in this function
class Boot {
    private _target: string;
    private _finished: boolean;
    constructor(target: string) {
        this._target = target;
        this._finished = false;
    }

    get target(): HTMLDivElement {
        return document.querySelector(this._target)!;
    }

    start() {
        if (this._finished) return;
        if (this.target === null) {
            setTimeout(() => this.start(), 100);
            return;
        }
        this.target.style.setProperty("display", "block", "important");
        this.target.style.setProperty("visibility", "inherit", "important");
    }

    finish() {
        if (this._finished) return;
        if (this.target === null) {
            setTimeout(() => this.finish(), 100);
            return;
        }
        this._finished = true;
        this.target.style.removeProperty("display");
        this.target.style.removeProperty("visibility");
    }
}

function docReady(fn: () => void) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        (setImmediate||((f: () => void) => setTimeout(f, 1)))(fn);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}    

const boot = new Boot(".spinner-container");
export default boot;
docReady(() => { boot.start(); });
