// This module is loaded early.
// Do not import in this module anything that uses tm:
//

// NOTE: This file implements the core machinery behind tm:
const __inlineModuleCounter = (() => {
    let value = 0;
    return () => {
        value += 1;
        return value;
    }
})();

class UndefinedModuleException extends Error {
    module: string;
    parent: string;

    constructor(message: string, module: string = "<none>", parent: string = "<none>") {
        super(message || "");
        this.name = "UndefinedModuleException";

        this.module = module;
        this.parent = parent;
    }
}

function compact<V>(arr: (V|null|undefined)[]): V[] {
    return arr.filter((f) => !(f===null||f===undefined)) as V[];
}

export type Loader = (...requirements: any[]) => any;

interface ModuleData {
    // Metadata
    name: string,
    dependencies: string[],
    func: Loader,

    // Loader-Data
    loader: boolean,
    raw: boolean,

    // State
    loaded: boolean,
    exports: any,
    promise: Promise<any>|null,
    processed: boolean
}

interface Preloader {
    push(cb: (loader: LocalModuleLoader) => void): void,
    [Symbol.iterator]: () => Iterable<(loader: LocalModuleLoader) => void>
}

export type Preprocessor = (loader: LocalModuleLoader, value: string) => string;
export type Hook = (module: any) => void;

export class LocalModuleLoader {
    private modules: Record<string, ModuleData>;
    private hooks: Record<string, Hook[]>;
    private preprocessors: Record<string, Preprocessor>;

    public _push: Preloader;

    constructor() {
        this.modules = {};
        this.hooks = {};
        this.preprocessors = {};

        this.module('jQuery', [], () => (window as any).$);
        this.module('require' , [], () => this.__requireModuleImpl());

        this._push = {
            push: (cb) => cb(this),
            [Symbol.iterator]: function*() {}
        };
    }

    __requireModuleImpl() {
        return (name: string, dependencies: string[], func: Loader) => {
            // Simple require version:
            if (typeof name === "string" && !dependencies && !func) {
                if (!this.modules[name])
                    throw new ReferenceError("Module not found.");
                
                if (!this.modules[name].loaded)
                    throw new ReferenceError("Module not loaded");

                return this.modules[name].exports;
            }

            // Inline Require
            if (typeof name !== "string") {
                func = dependencies as any;
                dependencies = name as any;
                name = `$$_inlineModule${__inlineModuleCounter()}`;
            }
            if (typeof dependencies == "function") {
                func = dependencies as any;
                dependencies = [];
            }

            this.module(name, dependencies, func);
            const _afterFinish = () => delete this.modules[name];
            this._require(name, "<inline>").then(_afterFinish, _afterFinish);
        }
    }

    __resolveMagicNames(name: string): string {
        if (name[0] === "~") {
            let data = name.substring(1).split(":")
            let type = data.shift();
            if (!type) {
                throw ReferenceError("Missing preprocessor type.");
            }
            let args = data.join(":");

            if (this.preprocessors[type] !== undefined)  {
                try {
                    name = this.preprocessors[type](this, args);
                } catch (e) {
                    console.error(e);
                    throw new Error(`Failed to resolve magic module: ${name}`);
                }
            }
        }
        return name;
    }

    push_module(name: string, dependencies: string[], func: () => any, loader=false, raw=false) {
        if (!this.modules[name]) {
            if (process.env.NODE_ENV === 'development') {
                console.debug("Defining module: " + name);
            }

            this.modules[name] = {
                // Metadata
                name: name,
                dependencies: dependencies.map(dep => this.__resolveMagicNames(dep)),
                func: func,

                // Loader-Data
                loader: loader,
                raw: raw,

                // State
                loaded: false,
                exports: undefined,
                promise: null,
                processed: false
            }
        }
        return { require: () => { this._require(name, "<inline>") } }
    }

    define(name: string, dependencies?: string[], func?: Loader) {
        if (typeof name !== "string") {
            func = dependencies as any;
            dependencies = name as any;
            name = `$$_inlineModule${__inlineModuleCounter()}`;

            this.define(name, dependencies, func);
            const delete_module = () => delete this.modules[name];
            this.require(name).then(delete_module, delete_module);
        }

        if (func === undefined) {
            func = dependencies as any;
            dependencies = [];
        }

        return this.push_module(name, dependencies!, func!, false, true);
    }

    registerPreprocessor(name: string, cb: Preprocessor) {
        this.preprocessors[name] = cb;
    }

    module(name: string, dependencies?: string[], func?: Loader, loader?: boolean) {
        if (func === undefined) {
            func = dependencies as any;
            dependencies = [];
        }

        return this.push_module(name, dependencies!, func!, loader!, false!);
    }

    unload(name: string) {
        if (!this.modules[name]) return;
        this.modules[name].loaded = false
        this.modules[name].exports = undefined
        this.modules[name].promise = null
        this.modules[name].processed = false
    }

    async __load(definition: ModuleData) {
        let dependencies = [...definition.dependencies];
        let _exports: any = undefined;

        if (dependencies.indexOf("exports") != -1 || dependencies.indexOf("module") != -1) {
            _exports = {exports: {}};
        }

        dependencies = await Promise.all(
            definition.dependencies.map(
                async (dep) => {
                    if (dep === "exports") {
                        return _exports.exports;
                    } else if (dep === "module") {
                        return _exports;
                    } else {
                        return await this._require(dep, definition.name, false)
                    }
                }
            )
        );

        if (!definition.raw) {
            dependencies = compact(dependencies);
        }

        let exports = definition.func(...dependencies);
        if (definition.loader) {
            exports = await exports;
        }

        if (_exports != undefined)
            exports = _exports.exports;
        definition.exports = exports;
        definition.loaded = true;

        await Promise.all((this.hooks[definition.name]||[]).map(hook=>hook(exports)));

        return exports;
    }

    _load_definition_with_children(name: string, from_obj: string, chain: string[]) {
        if (chain.indexOf(name) >= 0) {
            throw new UndefinedModuleException(name + " has a cyclic dependency. Chain: " + chain.toString());
        }
        chain = [...chain, name];

        const definition = this.modules[name];
        if (definition === undefined)
            throw new UndefinedModuleException(name + " was not defined previously. (Dependency of: " + from_obj + ")", name);

        for (let child of definition.dependencies) {
            if (child === "exports" || child === "module") continue;
            this._load_definition_with_children(child, name, chain);
        }

        return definition;
    }

    async _require(name: string, from_obj: string, recurse=true) {
        name = this.__resolveMagicNames(name);
        const definition = recurse ? this._load_definition_with_children(name, from_obj, []) : this.modules[name];

        if (definition.loaded) {
            return await Promise.resolve(definition.exports);
        }

        if (!definition.promise)
            definition.promise = this.__load(definition);

        return await definition.promise;
    }

    async require(name: string): Promise<any> {
        return this._require(name, "<root>");
    }

    async require_esm(name: string, root="<root:esm>") {
        const startTime = new Date();
        let rightNow: Date;
        let lastExc = null;
        while (((rightNow = new Date()).valueOf() - startTime.valueOf()) < 60000) {
            try {
                return await this._require(name, root);
            } catch (e) {
                lastExc = e;
                if (e instanceof UndefinedModuleException) {
                    await new Promise(rs => setTimeout(rs, 100));
                    continue;
                };
                throw e;
            }
        }
        if (!!lastExc) throw lastExc;
    }

    require_now(name: string) {
        if (!(this.modules[name]||{loaded: false}).loaded) {
            throw new UndefinedModuleException("Nodule not loaded", name);
        }
        return this.modules[name].exports;
    }

    hook(name: string, cb: Hook) {
        if (typeof cb === "string") {
            const cbname = cb;
            cb = () => this.require(cbname);
        }

        if (!!(this.modules[name]||{}).loaded) {
            cb(this.modules[name].exports);
            return;
        }

        if (!this.hooks[name])
            this.hooks[name] = [];
        this.hooks[name].push(cb);
    }

    async page2(name: string, timeout=10000, ...args: any[]) {
        if (typeof timeout !== "number") {
            args = [timeout, ...args];
            timeout = 10000;
        }

        try {
            const func = await this.page(name, timeout);
            console.log(`Entrypoint ${name} found.`);
            await func(...args);
            if (process.env.NODE_ENV === 'development') {
                console.debug(`Entrypoint ${name} loaded.`, func);
            }
        } catch (e) {
            (window.reportError||console.error)(e, {
                module: name
            });
            (window as any).Flash?.error();
        }
    }

    async page(name: string, timeout=10000) {
        console.debug("Defined Page Module: " + name);
        const promise = (async () => {
            let waited = 0;
            let lastError: UndefinedModuleException|null = null;
            while (waited < timeout) {
                waited+=100;

                try {
                    let exports = await this._require(name, "<page>");
                    if (exports.__esModule)
                        exports = exports.default;
                    return exports;
                } catch (e) {
                    if (!(e instanceof UndefinedModuleException))
                        throw e;
                    else {
                        lastError = e;
                    }
                }

                await new Promise(rs => setTimeout(rs, 100));
            }

            throw lastError || (new UndefinedModuleException(`Module ${name} has not loaded after timeout.`));
        })();
        
        // Add a spinner so the user will never use an uninitialized site.
        (window as any).SpinnerWidget?.around(promise);
        return promise;
    }
}

if (!!(window as any).TMModuleLoader && !!(window as any).TMModuleLoader.modules) {
    console.group("Redefinition detected: requirements.ts");
    console.warn("ModuleLoader code was already executed. Skipping...");
    console.warn("ModuleLoader-Object:", (window as any).TMModuleLoader);
    console.groupEnd();
} else {
    console.debug("Using the internal Module Loader.");
    const modules = new LocalModuleLoader();
    require("./require_script").default(modules);
    
    (window as any)["__tmimport"] = async (name: string) => {
        return await modules.require_esm(name, "webpack:@ticketmachine/backendv1");
    };

    for (let cb of ((window as any).TMModuleLoader || {})._push || []) {
        try {
            cb(modules);
        } catch (e) {
            window.reportError(e);
        }
    };

    (window as any).TMModuleLoader = modules;
    (window as any).TMModuleLoader.module('widgets/modules', ()=>modules);
}

const currentLoader = (window as any).TMModuleLoader;
export default currentLoader;
