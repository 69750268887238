export default function load(loader) {
    const _reuseSameArguments = (func) => {
        const argMap = new Map();
    
        return (...args) => {
            const key = JSON.stringify(args);
            if (argMap.has(key)) return argMap.get(key);
            
            const result = func(...args);
            argMap.set(key, result);
            return result;
        }
    }
    
    loader.module('require_script', function _module__require_script() {
        return (function require_script(script_path, essential) {
            if (essential === false && !window.OptOutConfig.is_activated()) {
                return WidgetAPI.promise(function(_, reject){reject('3rd-party library loading blocked.')});
            }
    
            return WidgetAPI.promise(function(resolve, reject) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.onload = resolve;
                script.src = script_path;
                document.getElementsByTagName('head')[0].appendChild(script);
            });
        });
    });
    
    loader.module('require_css', function _module__require_css() {
        return (function require_css(css_path, essential) {
            if (essential === false && !window.OptOutConfig.is_activated()) {
                return WidgetAPI.promise(function(_, reject){reject('3rd-party css loading blocked.')});
            }
            return WidgetAPI.promise(function(resolve, reject) {
                var link = document.createElement('link');
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = css_path;
                link.onload = resolve;
                document.getElementsByTagName('head')[0].appendChild(link);
            });
        });
    });
    
    loader.noop = function(name, value) {
        loader.module(name, [], function __module_require_dynamic(){return value;});
        return name;
    };
    
    (function(){
        var _static_id = 0;
        loader.static = function(value) {
            var name = "@static:" + _static_id;
            _static_id = _static_id +  1;
            loader.module(name, [], function __module_require_dynamic() {
                return value;
            });
            return name;
        }
    
        loader.registerPreprocessor("static", (_, args) => {
            let __ppval;
            ("__ppval = " + args);
            return loader.static(__ppval);
        });
    })();
    
    loader.alias = function(old_name, new_name) {
        loader.module(new_name, [old_name], function __module_require_dynamic(v) {
            return v;
        });
        return new_name;
    };
    
    loader.object = function(exposed, name, requirements) {
        if (!name)
            name = "@object:" + exposed;
    
        if (!requirements)
            requirements = [];
        
        loader.module(name, requirements, function __module_require_dynamic() {
             return window[exposed_name];
        });
    
        return name;
    };
    
    (function(){
        loader.url_endpoint = _reuseSameArguments(function() {
            const _arguments = [...arguments];
    
            const name = "@url:" + JSON.stringify(_arguments);;
            loader.module(name, ['widgets/helpers/ajax'], function __module_require_dynamic(AjaxHelpers) {
                return AjaxHelpers.resolve_url.apply(null, _arguments);
            }, true);
            return name;
        });
        
        loader.registerPreprocessor("endpoint", (_, args) => {
            let __ppval;
            eval("__ppval = " + args);
            return loader.url_endpoint(__ppval);
        });
    })();
    
    loader.load = function() {
        var _load_name = loader.url_endpoint.apply(null, arguments)
        var name = "@load:" + _load_name;
        loader.module(name, [_load_name, 'widgets/helpers/ajax'], function __module_require_ajax(url, AjaxHelpers) {
            return AjaxHelpers.request.get(url);
        }, true);
        
        return name;
    };
    loader.registerPreprocessor("load", (_, args) => {
        let __ppval;
        eval("__ppval = " + args);
        return loader.load(__ppval);
    });
    
    loader.asset = function(path) {
        var name = "@asset:" + path;
        loader.module(name, ['widgets/helpers/ajax'], function __module_require_dynamic(AjaxHelpers) {
            return AjaxHelpers.static_asset(path, {contentType: 'text/html'});
        }, true);
        return name;
    };
    loader.registerPreprocessor("asset", (_, args) => {
        return loader.asset(args);
    });
    
    (function(){
        loader.permission = function(permission) {
            var has_permission = loader.url_endpoint({controller: "permissions", action: "check"});
            var name = "@permission:" + permission;
    
            loader.module(name, [has_permission, 'widgets/helpers/ajax', 'widgets/helpers/promise'], function(has_permission_url, AjaxHelper) {
                return AjaxHelper.request.get(has_permission_url, {activity: permission}).then(function(response) {
                    return response.result;
                });
            }, true);
            return name;
        };
        loader.registerPreprocessor("permission", (_, args) => {
            return loader.permission(args);
        });
    })();
    
    loader.js = function(url, exposed_name) {
        var name = "@js:" + url;
        loader.module(name, ['require_script'], function __module_require_dynamic(require_script){
            if (window[exposed_name] !== undefined) return Promise.resolve(window[exposed_name]);
            return require_script(url).then(function() {
                if (exposed_name !== undefined)
                    return window[exposed_name];
            });
        });
        return name;
    };
    
    loader.css = function(url) {
        var name = "@css:" + url;
        loader.module(name, ['require_css'], function __module_require_dynamic(require_css){
            return require_css(url);
        });
        return name;
    };
    
    (function() {
        loader.frontend = _reuseSameArguments(function(requested_name) {
            var name = "@frontend:" + requested_name;
            loader.module(name, ['frontend'], function __module_require_dynamic(webshop) {
                for (let path of webshop.pathes) {
                    let target = !!path ? `${path}/${requested_name}` : requested_name;
                    if (!loader.modules[target]) continue;
                    return loader.require(target);
                }
                return loader.require(requested_name);
            }, true);
            return name;
        });
    
        loader.registerPreprocessor("webshop", (_, args) => {
            return loader.frontend(args);
        });
    })();
    
    loader.alias('underscore', '_');
    
    loader.noop('bootstrap');
    loader.noop('font-awesome');
};
