import loader from "./modules";

(function(window, name) {
    function __getBackendSpinnerContainer() {
        return document.querySelector(".spinner-container")
    }

    class SpinnerType extends Widget {
        constructor() {
            super();
            this._body = null;
            this.counter = 0;
        }

        /**
         * @type HTMLElement
         */
        get __body() {
            return this._body = ((!this._body || this._body.length==0 ) ? __getBackendSpinnerContainer() : this._body);
        }

        __onlyIfHasBody(f) {
            if (!!this.__body) {
                f();
            }
        }

        __hideSpinner() {
            this.__body.classList.add("hidden");
        }

        __showSpinner() {
            this.__body.classList.remove("hidden");
        }

        hide_until(promise) {
            this.__onlyIfHasBody(() => {
                const counter = this.counter;
                this.counter = 0;
                this.hide();

                const display = () => {
                    this.counter += counter;
                    if (this.counter >= 0) this.__showSpinner();
                }
                promise.then(display, display);
            });
            return promise;
        }

        show() {
            this.__onlyIfHasBody(() => {
                if (this.counter <= 0)
                    this.__showSpinner();
                    
                this.counter += 1;
            });
        }

        hide() {
            this.__onlyIfHasBody(() => {
                this.counter -= 1;
                if (this.counter <= 0) {
                    this.counter = 0;
                    this.__hideSpinner();
                }
            });
        }

        around(promise) {
            this.__onlyIfHasBody(() => {
                (async()=>{
                    this.show();
                    try {
                        await promise;
                    } catch (e) {
                    } finally {
                        this.hide();
                    }
                })().then(()=>{}, window.reportError);
            });
            return promise;
        }

        wrap(func) {
            return function(...args) {
                return Singleton.around(func.apply(this, args));
            }
        }

        run(func) {
            return this.around(func());
        }
    }

    window[name] = window[name] || new SpinnerType();
    let Singleton = window[name];

    loader.module('widgets/v1/spinner', function() {return window[name];});
    loader.module('widgets/spinner', function() {return window[name];});
})(window, "SpinnerWidget");
