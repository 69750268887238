import loader from "./modules";

(function($, window, name) {
    var _is_page_unloading = (()=>{
        let __page_unloading = false;
        window.addEventListener("beforeunload", () => {
            __page_unloading = true;
        });
        return () => __page_unloading;
    })();

    class FlashMessage {
        constructor(jQo) {
            this.jQo = jQo;
            this.closed = $.Deferred();
            this.dismissed = $.Deferred();
            this.keep = ()=>{};
        }

        async close() {
            await new Promise((rs) => this.jQo.fadeTo("slow", 0.25, () => rs()));
            this.kill();
        }

        async dismiss() {
            this.dismissed.resolve();
            await this.close();
        }

        kill() {
            this.jQo.remove();
            this.dismissed.reject();
            this.closed.resolve();
        }

    }

    class Flash extends Widget {
        _get_flash_container() {
            return $("#flash_container");
        }

        run(
                func,
                success = {message: this.t('success.standard', 'Success!'), level: 'success'},
                error   = {message: this.t("error.standard", 'Failed!'), level: 'error'}
        ) {
            return this.for_promise(func(), success, error);
        }

        message(message, level="info") {
            const message_div = $(`<div class="alert alert-${level} text-center"><i class="alert-close fa fa-times pull-right" role="flash-close"></i>${message}</div>`)
            const result = new FlashMessage(message_div);

            message_div.find('[role="flash-close"]').click(() =>result.dismiss());
            this._get_flash_container().append(message_div);
            const _id = setTimeout(()=>result.close(), 30*1000);
            result.keep = () => clearTimeout(_id);

            if (_is_page_unloading()) {
                result.kill();
                return;
            }

            return result;
        }

        clear() {
            this._get_flash_container().empty();
        }

        push_flash(...args) {
            let flashes = JSON.parse(window.sessionStorage.getItem("_flashes") || '[]');
            flashes.push(args);
            window.sessionStorage.setItem("_flashes", JSON.stringify(flashes));
        }

        pull_flashes() {
            const flashes = JSON.parse(window.sessionStorage.getItem("_flashes") || '[]');
            window.sessionStorage.setItem("_flashes", "[]");
            for (let flash of flashes) {
                this.message(...flash);
            }
        }

        for_promise(
                promise,
                success = {message: this.t('success.standard', 'Success!'), level: 'success'},
                error   = {message: this.t("error.standard", 'Failed!'), level: 'error'}
        ) {
            try {
                promise.then(
                    () => { if(!success.message) return;this.message(success.message, success.level) },
                    (reason) => { 
                        if (!error.message) return;
                        if (!!reason.isHandledByHelper) return;
                        this.message(error.message, error.level)
                    }
                );
            } catch (e) { 
                console.error("Something went wrong.")
            } finally {
                return promise;
            }
        }

        for_failure_on(promise, error) {
            return this.for_promise(promise, {message: null}, error);
        }

        for_success_on(promise, success) {
            return this.for_promise(promise, success, {message: null});
        }
    }

    var for_level = function(level, errmsg) {
        Flash.prototype[level] = function(message) {
            if (!message && !!errmsg) {
                message = this.t(errmsg, errmsg);
            }

            return this.message(message, level);
        };
    };
    for_level("success", 'success.standard');
    for_level("error", 'error.standard');
    for_level("warning");
    for_level("info");

    window[name] = window[name] || new Flash();
    loader.module('widgets/flash', () => window[name]);
    loader.module('widgets/v1/flash', () => window[name]);

})(window.$, window, "Flash");

